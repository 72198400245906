<template>
  <b-card>
    <g-form @submit="post">
      <b-row>
        <!-- from Date -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="date"
            name="date"
          />
        </b-col>
        <!-- to Date -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="date"
            name="date"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <!-- from Code  -->
          <g-field
            :value.sync="selectedItem.fromCode"
            label-text="fromCode"
            name="code"
            rules="integer"
          />
        </b-col>
        <!-- to Code -->
        <b-col md="4">
          <g-field
            :value.sync="selectedItem.toCode"
            label-text="toCode"
            name="code"
            rules="integer"
          />
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col md="4">
          <b-form-group :label="$t('selectAll')" stacked>
            <b-form-checkbox
              v-model="selectAll"
              class="custom-control-primary"
              @change="toggleSelect(currentBranch)"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="pt-1">
        <b-col md="4">
          <b-form-group :label="$t('includeSales')">
            <b-form-checkbox
              v-model="selectedItem.includeSales"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('includeReturnSales')">
            <b-form-checkbox
              v-model="selectedItem.includeReturnSales"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('includeExpenses')">
            <b-form-checkbox
              v-model="selectedItem.includeExpenses"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('includeIncomes')">
            <b-form-checkbox
              v-model="selectedItem.includeIncomes"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('customerCollection')">
            <b-form-checkbox
              v-model="selectedItem.includeCollections"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('PaymentVoucher')">
            <b-form-checkbox
              v-model="selectedItem.includePayments"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('customerDiscountTransaction')">
            <b-form-checkbox
              v-model="selectedItem.includeCustomerDiscounts"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="$t('postSalary')">
            <b-form-checkbox
              v-model="selectedItem.includeSalary"
              class="custom-control-primary"
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
            v-permission="'gLPost'"
          >
            {{ $t("post") }}
          </b-button>
          <b-button
            class="mx-1"
            @click="unpost"
            variant="primary"
            v-permission="'gLUnPost'"
          >
            {{ $t("unpost") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>

export default {
  props: ['id'],
  data() {
    return {
      items: [],
      fields: [],
      selectAll: false,
      // storageTransfer Model
      selectedItem: {
        fromDate: '',
        toDate: '',
        fromCode: 0,
        toCode: 0,
        includeSales: false,
        includeReturnSales: false,
        includeExpenses: false,
        includeIncomes: false,
        includeCollections: false,
        includeCustomerDiscounts: false,
        includePayments: false,
        includeSalary: false
      },
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'actions',
        },
        {
          key: 'transactions',
        },
      ];
    },
  },
   watch: {
    'filter.fromDate'(newVal) {
      if (newVal > this.filter.toDate) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    },
    'filter.ToDate'(newVal) {
      if (this.filter.fromDate > newVal) {
        this.doneAlert({ title: this.$t('startDateShouldBelessThanEndDate'), type: 'error' });
        return false
      }
      return true
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.setDate();
  },
  methods: {
    validateYear(dateFrom, Dateto) {
      if (this.getDate(dateFrom) < this.getDate(this.fiscalYearStart) || this.getDate(Dateto) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    setDate() {
      this.selectedItem.fromDate = this.fiscalYearStart
      this.selectedItem.toDate = this.fiscalYearEnd
    },
    toggleSelect() {
      this.selectedItem.includeSales = this.selectAll;
      this.selectedItem.includeReturnSales = this.selectAll;
      this.selectedItem.includeExpenses = this.selectAll;
      this.selectedItem.includeIncomes = this.selectAll;
      this.selectedItem.includeCollections = this.selectAll;
      this.selectedItem.includeCustomerDiscounts = this.selectAll;
      this.selectedItem.includePayments = this.selectAll;
      this.selectedItem.includeSalary = this.selectAll;
    },
    post() {
      if (!this.validateYear(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      // if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      if (this.selectedItem.fromCode === '' || this.selectedItem.fromCode === 0) this.selectedItem.fromCode = null;
      if (this.selectedItem.toCode === '' || this.selectedItem.toCode === 0) this.selectedItem.toCode = null;
      this.create({
        url: 'Vouchers/post',
        data: this.selectedItem,
      })
        .then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
        }).catch(({ data }) => {
          this.doneAlert({ title: this.$t(data.detail), type: 'error' });
        });
    },
    unpost() {
      // if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      if (this.selectedItem.fromCode === '' || this.selectedItem.fromCode === 0) this.selectedItem.fromCode = null;
      if (this.selectedItem.toCode === '' || this.selectedItem.toCode === 0) this.selectedItem.toCode = null;
      this.create({
        url: 'Vouchers/unpost',
        data: this.selectedItem,
      })
        .then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
        }).catch(({ data }) => {
          this.doneAlert({ title: this.$t(data.detail), type: 'error' });
        });
    }
  },
};
</script>
